import { FormEvent, HTMLAttributes, useState } from "react";
import api from "../../lib/api";
import TemplateSelector from "../TemplateSelector";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const defaultCloseDate = new Date();
defaultCloseDate.setHours(defaultCloseDate.getHours() + 3);

const Add = ({ children, ...props }: Props) => {
  const [title, setTitle] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [closeAtDate, setCloseAtDate] = useState(
    defaultCloseDate.toLocaleDateString("sv")
  );
  const [closeAtTime, setCloseAtTime] = useState(
    `${defaultCloseDate.getHours()}:${
      defaultCloseDate.getMinutes() < 10 ? "0" : ""
    }${defaultCloseDate.getMinutes()}`
  );

  const createBoard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const closeAt = new Date(`${closeAtDate}T${closeAtTime}:00`).toISOString();

    try {
      const board = await api.createBoard({
        title,
        template_id: templateId,
        close_at: closeAt,
      });
      window.location.pathname = `/${board.code}`;
    } catch (err) {
      if (err instanceof Error) {
        alert(err.message);
      } else {
        alert(err);
      }
    }
  };

  return (
    <div {...props}>
      <h1>Create a new scoreboard</h1>

      <p>
        Your board will get a unique ID that you can share with your friends
      </p>

      <form onSubmit={createBoard}>
        <div className="form-field">
          <label htmlFor="f_title">Title</label>
          <input
            id="f_title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: "80%" }}
          />
        </div>

        <TemplateSelector
          value={templateId}
          onChange={(templateId: string) => setTemplateId(templateId)}
        />

        <div className="form-field">
          <label htmlFor="f_date">Close date</label>
          <p className="form-field__hint">
            After this date/time, the board will be locked, the results shown
            and no more votes will be taken
          </p>
          <input
            id="f_date"
            type="date"
            value={closeAtDate}
            onChange={(e) => setCloseAtDate(e.target.value)}
          />
          <input
            id="f_time"
            type="time"
            value={closeAtTime}
            onChange={(e) => setCloseAtTime(e.target.value)}
          />
        </div>

        <div>
          <button>Create board</button> or <a href="/">cancel</a>
        </div>
      </form>
    </div>
  );
};

export default Add;
