import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  error?: string;
}

const ErrorPage = ({ children, error, ...props }: Props) => (
  <div {...props}>
    <h1>{error ?? "An error has occurred"}</h1>
    {children}
  </div>
);

export default ErrorPage;
