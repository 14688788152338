import { HTMLAttributes, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api, { Board as BoardInterface, Score, Vote } from "../../lib/api";
import Board from "../Board";
import Results from "../Results";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const isClosed = (closeDate: string) => new Date(closeDate) < new Date();

const BoardPage = ({ children, ...props }: Props) => {
  const { id } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [board, setBoard] = useState({} as BoardInterface);
  const [vote, setVote] = useState(null as Vote | null);

  useEffect(() => {
    if (!id) return;

    api.getBoard(id).then((board) => {
      setBoard(board);

      if (board.meta?.vote_id && !isClosed(board.close_at)) {
        api.getVote(id, board.meta.vote_id).then((vote) => {
          setVote(vote);
          setLoaded(true);
        });
      } else {
        setLoaded(true);
      }
    });
  }, [id]);

  return loaded ? (
    isClosed(board.close_at) ? (
      <Results board={board} vote={vote} {...props} />
    ) : (
      <Board board={board} vote={vote} {...props} />
    )
  ) : (
    <></>
  );
};

export default BoardPage;
