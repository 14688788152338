import { FormEvent, HTMLAttributes, useEffect, useState } from "react";
import api from "../../lib/api";
import { useParams } from "react-router-dom";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const EditBoard = ({ children, ...props }: Props) => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [closeAtDate, setCloseAtDate] = useState("");
  const [closeAtTime, setCloseAtTime] = useState("");

  useEffect(() => {
    if (!id) return;

    api.getBoard(id).then((board) => {
      setTitle(board.title);

      const closeAt = new Date(board.close_at);

      setCloseAtDate(closeAt.toLocaleDateString("sv"));
      setCloseAtTime(
        `${closeAt.getHours()}:${
          closeAt.getMinutes() < 10 ? "0" : ""
        }${closeAt.getMinutes()}`
      );
    });
  }, [id]);

  const updateBoard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const closeAt = new Date(`${closeAtDate}T${closeAtTime}:00`).toISOString();

    try {
      await api.updateBoard(id, {
        title,
        close_at: closeAt,
      });
      window.location.pathname = `/${id}`;
    } catch (err) {
      if (err instanceof Error) {
        alert(err.message);
      } else {
        alert(err);
      }
    }
  };

  return (
    <div>
      {title && (
        <>
          <h1>Editing "{title}"</h1>

          <form onSubmit={updateBoard} {...props}>
            <div className="form-field">
              <label htmlFor="f_title">Title</label>
              <input
                id="f_title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ width: "80%" }}
              />
            </div>

            <div className="form-field">
              <label htmlFor="f_date">Close date</label>
              <p className="form-field__hint">
                After this date/time, the board will be locked, the results
                shown and no more votes will be taken
              </p>
              <input
                id="f_date"
                type="date"
                value={closeAtDate}
                onChange={(e) => setCloseAtDate(e.target.value)}
              />
              <input
                id="f_time"
                type="time"
                value={closeAtTime}
                onChange={(e) => setCloseAtTime(e.target.value)}
              />
            </div>

            <div>
              <button>Save changes</button> or <a href={`/${id}`}>back</a>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default EditBoard;
