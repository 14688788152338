import { HTMLAttributes, ReactNode } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const tokenContents = (token: string): ReactNode => {
  const x = atob(token.split(".")[1]);

  return <p style={{ maxWidth: "400px" }}>{x}</p>;
};

const WhoAmI = ({ children, ...props }: Props) => {
  const cookies = document.cookie.split(";").map((x) => x.split("="));

  return (
    <div {...props}>
      <h1>== debug information ==</h1>

      <p>
        If you got here by accident, <a href="/">return to the home page</a>.
      </p>

      <ul>
        {cookies.map((parts) => (
          <li>
            <strong>{parts[0]}:</strong>
            <pre
              style={{
                maxWidth: "400px",
                wordWrap: "break-word",
                whiteSpace: "break-spaces",
              }}
            >
              {parts.slice(1).join("\n")}
            </pre>
            {parts[0].trim() === "token" && parts[1] && tokenContents(parts[1])}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhoAmI;
