import { useEffect, useState } from "react";
import api, { Template } from "../lib/api";

interface Props {
  value: string;
  onChange: (val: string) => void;
}

const TemplateSelector = ({ value, onChange, ...props }: Props) => {
  const [templates, setTemplates] = useState([] as Template[]);

  useEffect(() => {
    api.getTemplates().then((templates) => {
      setTemplates(templates);
    });
  }, []);

  return (
    <fieldset className="form-field" {...props}>
      <legend>Template</legend>
      <p className="form-field__hint">
        This cannot be changed once your board has been created
      </p>
      {templates
        .filter((x) => x.id.includes("2023"))
        .map((template) => (
          <label className="form-field__checkbox" key={template.id}>
            <input
              name="template_id"
              type="radio"
              value={value}
              onChange={() => onChange(template.id)}
            />
            {template.label}
          </label>
        ))}
      <details>
        <summary>Other options</summary>
        <div>
          {templates
            .filter((x) => !x.id.includes("2023"))
            .map((template) => (
              <label className="form-field__checkbox" key={template.id}>
                <input
                  name="template_id"
                  type="radio"
                  value={value}
                  onChange={() => onChange(template.id)}
                />
                {template.label}
              </label>
            ))}
        </div>
      </details>
    </fieldset>
  );
};

export default TemplateSelector;
