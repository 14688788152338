import "@github/relative-time-element";
import { FormEvent, HTMLAttributes, useState } from "react";
import api, { Board, Vote } from "../lib/api";
import VoteEditor from "./VoteEditor";

interface Props extends HTMLAttributes<HTMLDivElement> {
  board: Board;
  vote: Vote | null;
}

const Board = ({ children, board, vote, ...props }: Props) => {
  const [name, setName] = useState(vote ? vote.name : "");
  const [scores, setScores] = useState(vote ? vote.scores : []);

  const createVote = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (vote) {
        await api.updateVote(board.code, vote.code, name, scores);
      } else {
        await api.createVote(board.code, name, scores);
      }
      window.location.reload();
    } catch (err) {
      if (err instanceof Error) {
        alert(err.message);
      } else {
        alert(err);
      }
    }
  };

  return (
    <div {...props}>
      <h1>{board.title}</h1>
      <p>
        Closes <relative-time datetime={board.close_at}></relative-time>
      </p>
      {board.meta?.owned && (
        <p className="c-help-text">
          This is your board! You can{" "}
          <a href={`/${board.code}/edit`}>edit it</a>.
        </p>
      )}
      <form onSubmit={createVote}>
        <div className="form-field form-field--small">
          <label htmlFor="f_name">Your name</label>
          <input
            id="f_name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <VoteEditor
          options={board.options}
          scores={scores}
          onChange={(scores) => setScores(scores)}
        />
        <div className="form-field">
          <button type="submit">{vote ? "Save changes" : "Submit"}</button>
        </div>
      </form>
      <p>
        Board code: <code>{board.code}</code>
      </p>
    </div>
  );
};
export default Board;
