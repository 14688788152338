import { MouseEvent, useEffect, useState } from "react";
import { Option, Score } from "../lib/api";
import CountryEmoji from "./CountryEmoji";

const scoreValues = [12, 10, 8, 7, 6, 5, 4, 3, 2, 1];

interface Props {
  options: Option[];
  scores: Score[];
  onChange: (scores: Score[]) => void;
}

const VoteEditor = ({ options, scores, onChange, ...props }: Props) => {
  const [activeScore, setActiveScore] = useState(false as number | false);

  const select = (code: string) => {
    if (!activeScore) return;

    let set = false;
    let newScores = scores.map((score) => {
      if (score.score === activeScore) {
        set = true;
        return { ...score, option: code };
      } else {
        return score;
      }
    });

    if (!set) {
      newScores.push({
        score: activeScore,
        option: code,
      });
    }

    setActiveScore(false);
    onChange(newScores);
  };

  const clear = (score: number, e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onChange(scores.filter((s) => s.score !== score));
  };

  useEffect(() => {
    document.body.classList.toggle("body--no-scroll", activeScore !== false);
  }, [activeScore]);

  return (
    <>
      <div className="c-scoreboard" {...props}>
        {scoreValues.map((score) => {
          const selection = scores.find((s) => s.score === score);
          const option = selection
            ? options.find((o) => o.code === selection.option)
            : null;

          return (
            <div
              className="c-scoreboard__item c-scoreboard__item--interactive"
              onClick={() => setActiveScore(score)}
              key={score}
            >
              <span className="c-scoreboard__score">{score}</span>
              <span
                className={`c-scoreboard__label ${
                  option || "c-scoreboard__label--unselected"
                }`}
              >
                {option ? (
                  <>
                    <CountryEmoji code={option.code} /> {option.label}
                  </>
                ) : (
                  `${score} points go to...`
                )}
              </span>
              {option && (
                <button
                  type="button"
                  className="c-scoreboard__cancel"
                  onClick={(e) => clear(score, e)}
                >
                  ×
                </button>
              )}
              <span className="c-scoreboard__selector">
                <span role="presentation">▼</span>
              </span>
            </div>
          );
        })}
      </div>
      <div className="c-modal" aria-modal={true} hidden={activeScore === false}>
        <div className="c-modal__window">
          <button
            type="button"
            className="c-modal__close"
            onClick={() => setActiveScore(false)}
            aria-label="Close"
          >
            x
          </button>
          <h2 className="c-modal__header">Your {activeScore} points go to</h2>
          {options.map((option) => {
            const selected =
              scores.findIndex((s) => s.option === option.code) > -1;

            return (
              <div
                className={`c-modal__option ${
                  selected ? "c-modal__option--disabled" : ""
                }`}
                onClick={() => selected || select(option.code)}
                key={option.code}
              >
                <CountryEmoji code={option.code} /> {option.label}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VoteEditor;
