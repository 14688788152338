import { FormEvent, HTMLAttributes, useState } from "react";
import api from "../../lib/api";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Home = ({ children, ...props }: Props) => {
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [boardId, setBoardId] = useState("");

  const validateBoard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setSearchDisabled(true);

    try {
      await api.getBoard(boardId);
      window.location.pathname = `/${boardId}`;
      setSearchDisabled(true);
    } catch (err) {
      alert("Could not find board");
      setSearchDisabled(true);
    }
  };

  return (
    <div {...props}>
      <h1>Enter a board ID</h1>

      <form onSubmit={validateBoard}>
        <input
          type="text"
          value={boardId}
          onChange={(e) => setBoardId(e.target.value)}
          disabled={searchDisabled}
        />
        <button type="submit">Go</button>
      </form>

      <p>
        Or <a href="/add">create your own</a>
      </p>
    </div>
  );
};

export default Home;
