interface Props {
  code: string;
}

const CountryName = ({ code }: Props) => {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

  return regionNames.of(code.toUpperCase());
};

export default CountryName;
