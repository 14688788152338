import { HTMLAttributes, useEffect, useState } from "react";
import api, { Board, Vote } from "../../lib/api";
import { useParams } from "react-router-dom";
import CountryEmoji from "../CountryEmoji";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const scoreValues = [12, 10, 8, 7, 6, 5, 4, 3, 2, 1];

const VoteDisplay = ({ children, ...props }: Props) => {
  const { id } = useParams();
  const { voteId } = useParams();

  const [board, setBoard] = useState({} as Board);
  const [vote, setVote] = useState({} as Vote);

  useEffect(() => {
    if (!id || !voteId) return;

    api.getBoard(id).then((board) => {
      setBoard(board);
      api.getVote(id, voteId).then((vote) => {
        setVote(vote);
      });
    });
  }, [id, voteId]);

  return board.code && vote.code ? (
    <div>
      <h1>{board.title}</h1>
      <p>The votes from {vote.name} are as follows</p>
      <div className="c-scoreboard">
        {scoreValues.map((score) => {
          const selection = vote.scores.find((s) => s.score === score);
          const option = selection
            ? board.options.find((o) => o.code === selection.option)
            : null;

          return (
            <div className="c-scoreboard__item" key={score}>
              <span className="c-scoreboard__score">{score}</span>
              <span className="c-scoreboard__label">
                {option ? (
                  <>
                    <CountryEmoji code={option.code} /> {option.label}
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default VoteDisplay;
