import "@github/relative-time-element";
import { FormEvent, HTMLAttributes, useState } from "react";
import api, { Board, Vote } from "../lib/api";
import VoteEditor from "./VoteEditor";
import CountryEmoji from "./CountryEmoji";
import CountryName from "./CountryName";

interface Props extends HTMLAttributes<HTMLDivElement> {
  board: Board;
  vote: Vote | null;
}

const Results = ({ children, board, vote, ...props }: Props) => {
  return (
    <div>
      <h1>{board.title}</h1>
      <p>This vote has closed and the final scores are in!</p>
      {board.meta?.vote_id && (
        <p>
          <a href={`/${board.code}/vote/${board.meta?.vote_id}`}>
            See your vote
          </a>
        </p>
      )}
      <div className="c-scoreboard c-scoreboard--two-column">
        {board.options
          .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
          .map((option) => (
            <div className="c-scoreboard__item" key={option.code}>
              <span className="c-scoreboard__flag">
                <CountryEmoji code={option.code} />
              </span>
              <span className="c-scoreboard__label">
                <strong>
                  <CountryName code={option.code} />
                </strong>
                <br />
                <span className="c-scoreboard__long-label">{option.label}</span>
              </span>
              <span className="c-scoreboard__score">{option.score ?? 0}</span>
            </div>
          ))}
      </div>
      {board.meta?.owned && (
        <p className="c-help-text">
          Did you close the board too early? You can still{" "}
          <a href={`/${board.code}/edit`}>edit it</a> and set a new "close date"
          in the future.
        </p>
      )}
    </div>
  );
};
export default Results;
