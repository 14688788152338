interface Error {
  parameter: string;
  message: string;
}

export interface Option {
  code: string;
  label: string;
  score?: number;
}

export interface Template {
  id: string;
  label: string;
  options: Option[];
}

export interface Board {
  code: string;
  title: string;
  options: Option[];
  close_at: string;
  meta?: {
    vote_id?: string;
    owned?: boolean;
  };
}

interface DataCreateBoard {
  title: string;
  template_id: string;
  close_at: string;
}

export interface Score {
  option: string;
  score: number;
}

export interface Vote {
  code: string;
  name: string;
  scores: Score[];
}

class BadRequestError extends Error {
  constructor(message: string, errors: Error[]) {
    super(message);
    this.message = `${message}:\n${errors
      .map((e) => ` - ${e.parameter}: ${e.message}`)
      .join("\n")}`;
  }
}

class Api {
  cache: { templates: Template[] } = { templates: [] };

  async _call(uri: string, config: RequestInit = {}) {
    config.credentials = "include";

    const response = await fetch(`/api${uri}`, config);
    const body = await response.json();

    if (response.status === 400 && body.errors) {
      throw new BadRequestError(body.description, body.errors);
    }
    if (response.status >= 300) {
      throw new Error(body.description);
    }

    return body;
  }

  async getTemplates(): Promise<Template[]> {
    if (!this.cache.templates.length) {
      this.cache.templates = (await this._call(`/template`)) as Template[];
    }
    return this.cache.templates;
  }

  async getBoard(id: string): Promise<Board> {
    return this._call(`/board/${id}`);
  }

  async getVote(boardId: string, voteId: string): Promise<Vote> {
    return this._call(`/board/${boardId}/votes/${voteId}`);
  }

  async createBoard(data: DataCreateBoard): Promise<Board> {
    return this._call(`/board`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateBoard(
    boardId: string,
    data: { title: string; close_at: string }
  ): Promise<Board> {
    return this._call(`/board/${boardId}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }

  async createVote(
    boardId: string,
    name: string,
    scores: Score[]
  ): Promise<Vote> {
    const data = {
      name,
      scores,
    };

    return this._call(`/board/${boardId}/votes`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateVote(
    boardId: string,
    voteId: string,
    name: string,
    scores: Score[]
  ): Promise<Vote> {
    const data = {
      name,
      scores,
    };

    return this._call(`/board/${boardId}/votes/${voteId}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }
}

export default new Api();
