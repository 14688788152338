import { Route, Routes } from "react-router-dom";

import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Add from "./pages/Add";
import Board from "./pages/Board";
import EditBoard from "./pages/EditBoard";
import VoteDisplay from "./pages/VoteDisplay";
import WhoAmI from "./pages/WhoAmI";

const App = () => {
  return (
    <div className="container">
      <Routes>
        <Route path="/add" element={<Add />} />
        <Route path="/whoami" element={<WhoAmI />} />
        <Route path="/:id" element={<Board />} />
        <Route path="/:id/edit" element={<EditBoard />} />
        <Route path="/:id/vote/:voteId" element={<VoteDisplay />} />
        <Route path="/" element={<Home />} />
        <Route
          path="*"
          element={
            <ErrorPage>
              <p>Page not found</p>
            </ErrorPage>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
