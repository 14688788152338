interface Props {
  code: string;
}

const CountryEmoji = ({ code }: Props) => {
  const codePoints = code
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));

  return <>{String.fromCodePoint(...codePoints)}</>;
};

export default CountryEmoji;
